import {useDropzone} from 'react-dropzone'
import react, { useCallback, useEffect, useRef, useState } from 'react'
import './style.css'
import { AIGenIcon, AlertIcon, CopyTextIcon, FileAdd, HumanIcon, Spinner } from '../icons'
import { ProgressBar, getColor } from '../progressBar';
import { sendFileRequest, sendRequest } from '../../requests';
import { Puff } from 'react-loader-spinner';

interface ITag{
    children: string;
    bordered?: boolean;
}
const Tag: react.FC<ITag> = (props) => {
    return <span className={'tag__container ' + (props.bordered ? 'tag__bordered' : "")}>{props.children}</span>
}


export const FileDrop: react.FC = () => {
    const onDrop = useCallback((acceptedFiles: any) => {
        setFile(
            acceptedFiles[0]
        );
    }, []);
    const [file, setFile] = react.useState();
    const [text, setText] = react.useState('');
    const accept = {
        // 'application/pdf': ['.pdf'],
        // "application/vnd.openxmlformats-officedocument.wordprocessingml.document": ['.docx'],
        'text/plain': ['.txt']
    }
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, accept, maxSize: 5242880/5});
    const [loading, setLoading] = useState(false);
    const [percents, setPercents] = react.useState(-1);
    const [error, setError] = useState('');
    const [segments, setSegments] = useState('');
    const textareaRef = useRef<any>(null);

    useEffect(() => {
        document.addEventListener('keydown', function(event) {
            const ta = document.getElementById('textarea')
            if (document.activeElement == ta){
                return;
            }
            if (event.metaKey && event.key === 'v' ||  event.ctrlKey && event.key == 'v') {
                navigator.clipboard.readText().then((e) => {
                    setText(text+e);
                });
            }
        });
    }, [text]);
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('text')) {
            setText(urlParams.get('text')!)
        }
    }, []);

    return <div className='uploader__container'>
        <div>
            <div 
                className={"textarea " + (error ? 'textarea__alert-parent' : '')}
            >
                {
                    error ?
                    <div className="alert__container">
                    <AlertIcon />
                    <span className='alert__text'>
                        Not enough text. Please, type more than 250 characters.
                    </span>
                </div> : <></>
                }
                
                {/* <div
                    ref={textareaRef}
                    contentEditable={true} 
                    id="textarea" 
                    className={
                        error.length ?
                        'textarea__alert-container' : ''
                    }
                    onInput={(e) => {
                        setText(e.currentTarget.innerText.slice(0, 5000));
                        setFile(undefined);
                    }}
                    dangerouslySetInnerHTML={{__html: segments.length ? segments : ""}}
                ></div> */}
                <textarea ref={textareaRef} onChange={(e) => {
                    setText(e.target.value.slice(0, 5000));
                    setFile(undefined);
                }}
                value={text}
                id='textarea'
                placeholder='Enter text or upload document to run scan (currently only English supported)'
                ></textarea>
            </div>
            
            {
                text.length ? 
                <></> :
                <div className='drop__container'>
                    <div className="poschange__1">
                        <div {...getRootProps()} className={'filedrop__container ' + ( isDragActive ? 'filedrop__container-active' : "")}>
                            <input {...getInputProps()} />
                            <div className="filedrop__content">
                                <FileAdd />
                                <span className='upload__head'>
                                    {
                                        file ? 
                                        (file as any).path :
                                        isDragActive ?
                                        "Drag document here" :
                                        "Click to upload document"
                                    }
                                </span>
                                <div className="tags">
                                    {/* <Tag>PDF</Tag>
                                    <Tag>DOCX</Tag> */}
                                    <Tag>TXT</Tag>
                                    <Tag bordered>{"<1 MB"}</Tag>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="filedrop__container or__container">
                        or
                    </div>
                    <div className="poschange__2">
                        <div className='filedrop__container filedrop__container1' onClick={() => {
                            navigator.clipboard.readText().then((e) => {
                                setText(text+e);
                            });
                        }}>
                            <div className="filedrop__content">
                                <CopyTextIcon />
                                <span className='upload__head'>
                                    Click to paste text
                                </span>
                                <div className="tags">
                                    {/* <Tag>PDF</Tag>
                                    <Tag>DOCX</Tag> */}
                                    <Tag bordered>{">250 characters"}</Tag>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className="textarea__controll">
                <div className="words__count">
                    <div className="words">
                        {text.length} / 5000 characters
                    </div>
                    <ProgressBar 
                        progress={text.length / 5000 * 100}
                    />
                </div>
                <button className='primary' style={{width: 164}} onClick={() => {
                    setLoading(true);
                    setPercents(-1);
                    setError('');
                    if (file) {
                        sendFileRequest(file).then((e) => {
                            setPercents(Number.parseFloat(e) * 100);
                            setLoading(false);
                            if (Number.isNaN(Number.parseFloat(e))) {
                                setError(e);
                            }
                            window.dispatchEvent(new Event("storage"));
                        })
                    } else {
                        sendRequest(text).then((e) => {
                            setLoading(false);

                            if (e.answer != null) {
                                setPercents((e.answer as any) * 100);

                                setSegments(e.segments);
                            }
                            else {
                                setError(e.error);
                            }
                            window.dispatchEvent(new Event("storage"));
                        })
                    }
                }}>
                    {
                        "Scan"
                    }
                </button>
            </div>
            <div className='scan__container'>
                {
                    loading && percents == -1 ?
                    <div className="spin__container-b">
                        <div className="spin">
                            <Spinner />
                        </div>
                        <div className="spin__container">
                            Scanning...
                        </div>
                    </div> :
                    Number.isNaN(percents) || percents == -1 ? 
                    <span className='scan__text'>
                        Click Run scan to see the results
                    </span> :
                    (
                        percents > 50 ? 
                        <div className="spin__container-b ai-gen__container">
                            <div className="">
                                <AIGenIcon />
                            </div>
                            <div className="red-container">
                                The text is likely AI-generated
                            </div>
                        </div> : 
                        <div className="spin__container-b human__container-out">
                            <div className="">
                                <HumanIcon />
                            </div>
                            <div className="human__container">
                                Human-written
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    </div> 
}