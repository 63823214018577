import axios from 'axios'
import {BACKEND_URL} from "./config";
import { getColor } from './elements/progressBar';

export const bareRequest = async (text: string) => {
    const data = await axios.get(BACKEND_URL + 'bare-request?text=' + text.replaceAll('\n', '%0A'));
    return data.data.answer;
}

export const signUp = async (privyId: string, email: string) => {
    const data = await axios.post(BACKEND_URL + 'users/signup/', {username: email, password: privyId});
    return data.data.token;
}

export function buildMock(mocked: {text: string, color: string}[]) {
    console.log(mocked)
    return mocked.map((e) => `<span style="color: ${e.color}">${e.text}</span>`).join(' ')
}

function generateMock(text: string) {
    console.log(text)
    var splitted = text.split(' ');
    
    return splitted.map((e) => 
        {
            return {'text': e, 'color': getColor(Math.random() * 100)}

        })
}

export const sendRequest = async (text: string) => {
    try {
        const data = await axios.post(
            BACKEND_URL + 'text',
            {'text': text},
            {
                headers: {
                    'Authorization': 'Token ' + localStorage.getItem('token')
                }
            }
        );
        return {
            "answer": parseFloat(data.data.answer).toFixed(2) as any as number, 
            "error": null, 
            "segments": buildMock(generateMock(text))
        };
    } catch (e){
        return {
            "answer": null,
            "error": (e as any).response.data.error[0],
            "segments": null
        }
    }
    
}

export const sendFileRequest = async (file: File) => {
    var bodyFormData = new FormData();
    bodyFormData.append('file', file);
    try {
        const data = await axios.post(
            BACKEND_URL + 'text',
            bodyFormData,
            {
                headers: {
                    'Authorization': 'Token ' + localStorage.getItem('token'),
                    "Content-Type": "multipart/form-data"
                }
            }
        );
        return data.data.answer;

    } catch (e){
        return (e as any).response.data.error[0]
    }
    
}

export const createAPIKey = async (name: string) => {
    if (!name.length) name = '-';
    var data = await axios.post(
        BACKEND_URL+'key', {name: name},
        {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        }
    );
    return data.data;
}

export const getApiKeys = async () => {
    var data = await axios.get(
        BACKEND_URL + 'key',
        {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        }
    );
    return data.data;
}


export const deleteAPIKey = async (key: string) => {
    var data = await axios.delete(
        BACKEND_URL + 'key/' + key,
        {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        }
    );
    return data.data;
}


export const getToken = async (privyId: string, email: string) => {
    try {
        const response = await axios.post(BACKEND_URL + 'auth-token/', {username: email, password: privyId}, {headers: {"Content-Type": 'application/json'}});
        return response.data;
    } catch {
        return {token: await signUp(privyId, email)};
    }
}


export const updateAPIKey = async (key: string, title: string) => {
    var data = await axios.patch(
        BACKEND_URL + 'key/' + key,
        {
            name: title
        },
        {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token') 
            }
        }
    );
    return data.data;
}


export const getWordsUsed = async () => {
    try {
        var data = await axios.get(
            BACKEND_URL + 'users/me/',
            {
                headers: {
                    'Authorization': 'Token ' + localStorage.getItem('token')
                }
            }
        );
        return data.data;
    } catch {
        return 'error'
    }
    
}


export const getAnalytics = async (trashold: number) => {
    var data = await axios.post(
        BACKEND_URL + 'analitycs',
        {
            trashold
        },
        {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        }
    );
    return data.data;
}