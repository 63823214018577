import { ResponsiveLine } from "@nivo/line";
import react, { useEffect, useState } from 'react';
import './style.css'
import 'react-tooltip/dist/react-tooltip.css'
import { TooltipIcon } from "../icons";


interface IChart {
    header: string;
    chartData: {
        x: string,
        y: string
    }[];
}

export const Chart: react.FC<IChart> = (props) => {
    const height = 290;
    const [width, setWidth] = useState(300);


    const gradProps = {
        gradientUnits: 'userSpaceOnUse',
        x1: '0',
        y1: '0',
        x2: '0',
        y2: height
    };
      
    const chartData = [
          {
            "id": "1",
            "color": "hsl(195, 70%, 50%)",
            "data": props.chartData
          },
    ]

    useEffect(() => {
        let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        setWidth((vw - 320 - 80) / 2 - 30);
    }, []);
    var values = props.chartData.map((e) => e.y).sort();
    var isDataEmpty = false;
    if ((values[values.length-1] as any) == 0) {
        isDataEmpty = true;      
    }
    return <div className="chart__container">
        <div className="chart__header">
            {props.header}
        </div>
        <div style={{ height, width }}>
            <svg style={{height: 0, width: 0}}>
                    <defs>
                    <linearGradient id="someGradientId" {...gradProps} >
                        <stop offset="25%" stopColor="var(--primary-color)" />
                        <stop offset="100%" stopColor="rgba(255,255,255,0)" />
                    </linearGradient>
                    </defs>
            </svg>
            {
                isDataEmpty ?
                <div className="no-data__container">No data available</div> : 
                <ResponsiveLine
                data={chartData}
                colors={['url(#someGradientId)']}
                // dotSize={0}
                margin={{ top: 20, right: 50, bottom: 50, left: 50 }}
                enableArea={true}
                enableGridX={true}
                enableGridY={true}
                curve={"monotoneX"}
                tooltip={point => {
                    return <div className="tooltip__container">
                        <span className="tooltip__text">{point.point.data.y.toString()}</span>
                        <div className="tooltip-icon__container">
                            <TooltipIcon />
                        </div>
                    </div>;
                  }}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: '',
                    legendOffset: 36,
                    legendPosition: 'middle',
                    truncateTickAt: 0,
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: '',
                    legendOffset: -40,
                    legendPosition: 'middle',
                    truncateTickAt: 0
                }}
                pointColor={{ theme: 'background' }}
                pointBorderWidth={2}
                // pointBorderColor={{ from: 'serieColor' }}
                pointLabelYOffset={-12}
                enableTouchCrosshair={true}
                useMesh={true}
                yFormat=" >-.2f"
                xScale={{ type: 'point' }}
                yScale={{
                    type: 'linear',
                    min: 'auto',
                    max: 'auto',
                    stacked: true,
                    reverse: false
                }}
                theme={{
                    text: {
                        fill: '#C1C2C6',
                        fontFamily: 'Satoshi-Medium'
                    },
                    grid: {
                        line: {
                            stroke: "#F6F8FB"
                        }
                    }
                }}
            />
            }


            </div>
    </div> 
};