import react, { useEffect, useState } from 'react'
import {PrivyProvider} from '@privy-io/react-auth';
import { RouterProvider, useLocation } from 'react-router-dom';
import router from '../../router'
import { Sidebar } from '../../elements/sidebar';
import './style.css'

const prodId = 'clw85551r0351g4avkyfj13x8'
const localId = 'clvfi2bul00bbg4oekhoy2lyr'

export const Application: react.FC = () => {
    const [suffix, setSuffix] = useState('');
    const [update, setUpdate] = useState(false);
    useEffect(() => {
        var splittedHref = window.location.href.split('/')
        setSuffix(splittedHref[splittedHref.length-1]);
        window.onhashchange = () => {
            setUpdate(!update);
        }
    }, [update]);

    return <PrivyProvider 
        appId={prodId}
    >
        <div className='app__container'>
            {
                suffix == 'extension' ? <></> : <Sidebar />
            }
            <RouterProvider router={router}/>
        </div>
        
    </PrivyProvider>
}