import react from 'react'
import './style.css'
import { Checkmark, Logo } from '../../../elements/icons'
import { useNavigate } from 'react-router-dom'


export const ExtensionLogin: react.FC = () => {
    const navigate = useNavigate();
    return <div className='extension-login__container'>
        <div className="extension-login__centered">
            <div className="extension-login__content">
                <Logo />
                <div className="extension-logo__content-actions">
                    <div className="information__container">
                        <div className="information">
                            <Checkmark />
                            <span>Login successful</span>
                        </div>
                        <span>Now you can start using the extension</span>
                    </div>
                    <div className="actions__centered">
                        <div className="actions__container">
                            <button className='primary' onClick={() => {
                                window.location.replace('/main/scan')
                            }}>Continue</button>
                            <button 
                                className='primary secondary'
                                onClick={() => {
                                    window.location.replace('/main/scan')
                                }}
                            >Continue in web</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}