import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Application } from "./pages/application";
import { Main } from "./pages/application/main";
import { Buffer } from "buffer";
import {Extension} from "./pages/extension";

window.Buffer = Buffer;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

// root.render(<Extension />);
root.render(<Application />)