import react, { useEffect, useState } from 'react'
import { ProgressBar } from '../progressBar'
import './style.css'
import { getWordsUsed } from '../../requests';


export const formatBigNumbers = (number: string) => {
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


export const CurrentPlan: react.FC = () => {
    const [wordsUsed, setWordsUsed] = useState(0);
    const [wordsGranted, setWordsGranted] = useState(0);

    useEffect(() => {
        getWordsUsed().then((e) => {
            setWordsGranted(e.granted_words);
            setWordsUsed(e.used_words);
        })
    }, []);
    return <div className='current-plan__container'>
        <div className="current-plan__content">
            <div className="current-plan__words">
                <span className="current-plan__text">
                    Current plan
                </span>
                <span className="current-plan__words-words">
                    {formatBigNumbers(wordsGranted.toString())} words
                </span>
            </div>
            <div className="progress__plan">
                <span className='words__used'>
                    <strong>{formatBigNumbers(wordsUsed.toString())}</strong> / {formatBigNumbers(wordsGranted.toString())} words used
                </span>
                <ProgressBar progress={wordsUsed/wordsGranted*100}/>
            </div>
        </div>
        <button className='primary disabled'>Paid plans coming soon</button>
    </div>
}