import react, { useEffect, useRef, useState } from 'react'
import { APIIcon, ArrowIcon, Logo, ScanIcon } from '../icons'
import {appRouter} from '../../router';
import './style.css'
import { Avatar } from '../avatar';
import { ProgressBar } from '../progressBar';
import { getWordsUsed } from '../../requests';
import { formatBigNumbers } from '../currentPlan';

const iconsSlots = {
    'scan': (active: boolean) => <ScanIcon active={active}/>,
    'api': (active: boolean) => <APIIcon active={active}/> 
}

interface ISlot{
    active: boolean;
    onClick: (id: string) => void;
    children: react.ReactNode | string;
    id: string;
    isApi?: boolean;
}
const Slot: react.FC<ISlot> = (props) => {
    var active = false;
    var link = props.id;
    var id = props.id;
    if (id.split('/').length > 1) {
        id = id.split('/')[1];
    }
    var pathname = window.location.pathname.split('/');
    if (props.active || 
        pathname[pathname.length-1] == id || 
        (
            props.id == 'api' && window.location.pathname.includes('api')
        )
    ) {
        active = true;
    }
    if (props.id == 'api') {
        link = 'api/subs'
    }

    var iconWidget = <div></div>;
    try {
        iconWidget = (iconsSlots as any)[props.id](active)
    } catch {};

    var location = window.location.pathname.split('/');
    var available = false;
    if (location.includes('api')) {
        available = true;
    }

    return <div 
            className={
                'slot__container' + 
                (
                    active ? "" : " slot__disabled"  
                ) + 
                (
                    props.isApi ? " slot__api" : ""
                ) +
                (
                    props.isApi && active ? " slot-api__active " : ""
                )
            } 
            id={props.id} 
            onClick={() => {
                props.onClick(props.id);
                appRouter.navigate('/main/'+link)
            }}
        >
        {
            iconWidget
        }
        <span className={'slot__text' + (active ? "" : " slot__text__disabled")}>
            {
                props.children == 'API' ?
                <div className='api__sidebar'>
                    <span>API</span>
                    <div className={'rotated ' + (available ? "rotated-back" : "")}>
                        <ArrowIcon />
                    </div>
                </div> :
                props.children
            }
        </span>
    </div>
}



export const Sidebar: react.FC = () => {

    const [availableWords, setAvailableWords] = useState(0);
    const [grantedWords, setGrantedWords] = useState(0);
    const requested = useRef(false);
    
    const slotChangeFunction = (id: string) => {
        setSlots(slots.map((e) => {
            return e.id != id ?
            e :
            {
                ...e,
                active: true
            }
        }));
    }
    const [slots, setSlots] = react.useState([
        {
            active: false,
            onClick: slotChangeFunction,
            id: 'scan',
            children: "Scan"
        },
        {
            active: false,
            onClick: slotChangeFunction,
            id: 'api',
            children: "API"
        },
        {
            active: false,
            onClick: slotChangeFunction,
            id: 'api/subs',
            children: "Subscription",
            isApi: true
        },
        {
            active: false,
            onClick: slotChangeFunction,
            id: 'api/table',
            children: "Keys",
            isApi: true

        },
        {
            active: false,
            onClick: slotChangeFunction,
            id: 'api/docs',
            children: "Docs",
            isApi: true

        },
    ]);
    var location = window.location.pathname.split('/');
    var available = false;
    if (location.includes('api')) {
        available = true;
    }

    var getUsed = () => {
        getWordsUsed().then((e) => {
            if (e == 'error') return;
            setGrantedWords(e.granted_words);
            setAvailableWords(e.used_words);
        });
    }

    useEffect(() => {
        if (!requested.current) {
            requested.current = true;
            getUsed();
        }
        localStorage.setItem('updated', '0');
        setInterval(() => {
            getUsed();
        }, 2000)
        // window.addEventListener('storage', () => {
        //     var updValue = Number.parseInt(localStorage.getItem('updated')!)
        //     localStorage.setItem('updated', (updValue+1).toString());
            
        // });
    }, []);
    return <div className='sidebar__container'>
        <div className="sidebar__content">
            <div className="sidebar__content-content">
                <div className="logo__container-f">
                    <Logo />
                </div>

                {/* <button 
                    className='sidebar__button'
                    onClick={() => {
                        window.location.replace('/main/scan')
                    }}
                >+ New scan</button> */}
                <div className="slots">
                    {
                        slots.filter((e) => {return !e.isApi}).map(e => <Slot {...e}/>)
                    }
                    {
                        available ?
                        <div className='slot__api-container'>
                        {
                            slots.filter((e) => {return e.isApi}).map(e => <Slot {...e}/>)
                        }
                        </div> : <></>
                    }
                    
                </div>
            </div>
            <div className="sidebar__avatar">
                <Avatar />
                <div className="progress-sidebar__container">
                    <ProgressBar 
                        progress={availableWords/grantedWords*100}
                    />
                    <span className='sb__text'>
                        <strong>{`${formatBigNumbers(availableWords.toString())} out of ${formatBigNumbers(grantedWords.toString())}`}</strong>
                        <br />
                        words used
                    </span>
                </div>

            </div>
        </div>
    </div>
}