import react, { useEffect, useRef, useState } from 'react'
import { Application } from '..'
import { PrivyClient, useLogin, usePrivy } from '@privy-io/react-auth'
import { getToken, signUp } from '../../../requests';
import { APITable, APIUsage, Layout } from './layout';
import router from '../../../router'


export const getLogin = (user: any) => {
    if (user == null) return "  ";
    if (user.email) return user.email.address;
    if (user.wallet) return user.wallet.address;
    if (user.github) return user.github.username;
    if (user.google) return user.google.email;
    return "";
}


export const Main: react.FC = () => {
    const {authenticated, ready} = usePrivy();
    const wasRequest = useRef(false);
    const {login} = useLogin({
        onComplete: (user, isNewUser, wasAlreadyAuth) => {
            var login = getLogin(user);
            if (wasAlreadyAuth) return;
            if (localStorage.getItem('token') != null) return;
            if (isNewUser) {
                signUp(user.id, login).then((e) => {
                    localStorage.setItem('token', e);
                    setTimeout(() => {
                        window.location.reload();
                    }, 500);
                    // window.dispatchEvent(new Event("ready"));
                });
            } else {
                getToken(user.id, login).then((e) => {
                    localStorage.setItem('token', e.token);
                    setTimeout(() => {
                        window.location.reload();
                    }, 500);
                    // window.dispatchEvent(new Event("ready"));
                })
            }
        },
        onError: (e) => {
            console.log(e, 'error')
            if (e === 'exited_auth_flow' && ready) {
                login();
            }
        }
    });
    // useEffect(() => {
    //     // if (!authenticated) {
    //     //     login();
    //     // }
    // }, [ready, authenticated]);
    useEffect(() => {
        if (!authenticated && ready && !wasRequest.current) {
            wasRequest.current = true;
            login();
        }
    }, [authenticated, login, ready]);
    
    var pathname = window.location.pathname.split('/');
    var lastpath = pathname[pathname.length-1];
    if (lastpath == 'main' || lastpath.length == 0) {
        window.location.replace('/main/scan')
    }
    if (lastpath == 'docs') {
        window.open('https://docs.its-ai.org/reference/text_create')
    }
    if (!ready) {
        return <></>
    }
    return <>
        {
            lastpath == 'scan' ?
            <Layout /> :
            lastpath == 'subs' ?
            <APIUsage /> : 
            <APITable />
        }
    </>
}